'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.users
 # @description

###
angular
  .module 'mundoAdmin.users', [
    'ui.router'
    'formly'
    'formlyMaterial'
  ]
  .config (
    formlyConfigProvider
  )->
    # Formly: list of checkboxes
    formlyConfigProvider.setType({
      name: 'permissionlist'
      templateUrl: 'mundo-admin/users/views/user-role-manager-permission-list.tpl.html'

      controller: [
        '$scope'
        ($scope) ->
            init = ->
              $scope.id = $scope.options.key
              $scope.model['indexedCategories'] = []
              $scope.model[$scope.options.key] = $scope.options.defaultValue
              $scope.initialRender = false
              $scope.loading=true
              return

            $scope.getPermList = () ->
              $scope.model['indexedCategories'] = []
              return $scope.options.templateOptions.options

            $scope.checkDefault = (opt) ->
              return false if not $scope.model[$scope.options.key]?
              return true for def in $scope.model[$scope.options.key] when def.permission is opt.permission

            $scope.updatePermissionList = () ->
              if ($scope.initialRender)
                $scope.model[$scope.options.key] = []
                for opt in $scope.options.templateOptions.options
                  if $scope.checkboxes[opt.permission] and not $scope.inherited(opt.permission)
                    $scope.model[$scope.options.key].push(opt)


            $scope.checkboxes = new Object()
            $scope.check = (opt) ->
              if not $scope.inherited(opt.permission)
                $scope.checkboxes[opt.permission] = ! $scope.checkboxes[opt.permission]
              $scope.updatePermissionList()
              #console.log($scope.model[$scope.options.key])
            $scope.toggleCheckbox = ($event, opt) ->
              $event.preventDefault()
              $scope.check(opt)

            $scope.filterCategories = (opt) ->
              newcat = $scope.model['indexedCategories'].indexOf(opt.category) == -1
              $scope.model['indexedCategories'].push(opt.category) if newcat
              if newcat
                $scope.model['indexedCategories'].sort (a, b) ->
                  labelA = a
                  if a.category?
                    labelA = a.category
                  labelB = b
                  if b.category?
                    labelB = b.category

                  labelA.toLowerCase().localeCompare(labelB.toLowerCase())
              show = newcat and (not $scope.model['categories']? or
               opt.category in $scope.model['categories'] or
               $scope.model['categories'].length == 0)
              return show

            $scope.filterPermission = (opt) ->
              #console.log(selectedTypes)
              return true if not $scope.model['types']
              if 'Permitted' is $scope.model['types']
                return $scope.checkboxes[opt.permission] and not $scope.inherited(opt.permission)
              if 'Inherited' is $scope.model['types']
                return $scope.inherited(opt.permission)
              if 'Editable' is $scope.model['types']
                return not $scope.inherited(opt.permission)
              if 'Not permitted' is $scope.model['types']
                return not $scope.checkboxes[opt.permission] and not $scope.inherited(opt.permission)
              return true

            # check if permission is inherited
            $scope.inherited = (permission) ->
              if permission in $scope.options.templateOptions.inheritedPermList
                $scope.checkboxes[permission] = false
                return true
              return false

            $scope.$watch('options.templateOptions.inheritedPermList' \
              ,(() -> $scope.updatePermissionList()) \
              , true)

            # initialRender is a boolean saying that the first render has happened
            $scope.finished = (last) ->
              $scope.initialRender = true if last



            init()
      ]
      })

    # Formly: filters for the permissions#
    formlyConfigProvider.setType({
      name: 'permissionFilters'
      templateUrl: 'mundo-admin/users/views/user-role-manager-permission-filters.tpl.html'
      controller: [
        '$scope'
        '$translate'
        ($scope,$translate) ->
          $scope.permissionTypes = ['All', 'Editable', 'Inherited', 'Permitted', 'Not permitted']
          $scope.permissionTypes.sort (a, b) ->
            labelA = $translate.instant "app.permissions.filters.type." + a
            labelB = $translate.instant "app.permissions.filters.type." + b
            labelA.toLowerCase().localeCompare(labelB.toLowerCase())
          $scope.model['types'] = 'All'
      ]
    })

    formlyConfigProvider.setType({
      name: 'childrolesDropdown'
      templateUrl: 'mundo-admin/users/views/user-role-manager-childroles.tpl.html'
      controller: [
        '$scope'
        ($scope) ->

      ]
    })
  .run [
    'MundoNavigation'
    (MundoNavigation) ->
      MundoNavigation.addSection
        state: 'users'
        label: 'app.admin.users'
        icon: 'people'
        showExpression: 'hasPermission("access user section")'
        items: [
            state: 'users.overview'
            label: 'app.admin.pageTitles.users.users'
            icon: 'people'
            showExpression: 'hasPermission("view all MundoMosaUserBundle:User entities") || \
              hasPermission("view all MundoMosaUserBundle:UserContext entities")'
          ,
            state: 'users.tenants'
            label: 'app.admin.pageTitles.users.tenants'
            icon: 'business'
            showExpression: 'hasPermission("view all MundoMosaUserBundle:Tenant entities")'
          ,
            state: 'users.groups'
            label: 'app.admin.pageTitles.users.userGroups'
            icon: 'supervisor_account'
            showExpression: 'hasPermission("view all MundoMosaUserBundle:UserContextGroup entities")'
          ,
            state: 'users.roles'
            label: 'app.admin.pageTitles.users.userRoles'
            icon: 'verified_user'
            showExpression: 'hasPermission("view all MundoMosaUserBundle:Role entities")'
        ]
  ]
